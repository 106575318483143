<template>
  <section class="sightsCarousel">
    <carousel :loop="true" :autoplay="true" :autoplayHoverPause="true" :autoplayTimeout="3000" :perPage="1" :navigationEnabled="true" :paginationEnabled="false" :navigationNextLabel="navLabel" :navigationPrevLabel="navLabel">
      <slide v-for="(banner, i) in banners" :key="i">
        <img :src="banner.logo" class="responsive-img">
        <p class="name">{{banner.title}}</p>
      </slide>
    </carousel>
  </section>
</template>
<script>
import { Carousel, Slide } from 'vue-carousel'
import { getBanners } from "@/api/api.js";

export default {
  components:{Carousel,Slide},
  data() {
    return {
      banners: [],
      timer: null,
      navLabel: '<span class="icon-zuohua"></span>'
    }
  },
   destroyed() {
    clearInterval(this.timer);
  },
  mounted() {
    getBanners().then((res) => {
      this.banners = res.data.data;
    });
    this.timer = setInterval(this.scrollAnimate, 60000);
  },
};
</script>
<style scoped>
.sightsCarousel{margin-top:20px}
.VueCarousel-slide{text-align: center;}
.responsive-img{width:398px;height:226px;border-radius: 8px;}
.name{color:#fff}
::v-deep .icon-zuohua{color:#a9a9a9;font-size: 32px;}
::v-deep .VueCarousel-navigation-prev{transform: translateY(-50%) translateX(0%)}

::v-deep .VueCarousel-navigation-next{transform: translateY(-50%) translateX(0%) rotate(180deg)}
::v-deep .VueCarousel-navigation--disabled{opacity: .2;}
::v-deep .VueCarousel-navigation-button:focus{outline: none}
</style>