<template>
  <section class="couponCarousel">
    <carousel :loop="true" :autoplay="true" :autoplayHoverPause="true" :autoplayTimeout="3000" :perPage="1" :navigationEnabled="true" :paginationEnabled="false" :navigationNextLabel="navLabel" :navigationPrevLabel="navLabel">
      <slide v-for="(coupon, i) in coupons" :key="i" class="coupon-slide">
        <!-- <img :src="coupon.image_id" class="responsive-img"> -->
        <div class="content">
          <span class="amount">{{ coupon.value_amount == "" ? `${parseFloat(coupon.rate)}折` : coupon.value_amount }}</span>
          <span class="name">{{ coupon.voucher_name }} / {{ coupon.voucher_type	== 'FULL_MONEY' ? "全场代金券" : "全场折扣券" }}</span>
        </div>
      </slide>
    </carousel>
  </section>
</template>
<script>
import { Carousel, Slide } from 'vue-carousel'
import { getCoupons } from "@/api/api.js";

export default {
  components:{Carousel,Slide},
  data() {
    return {
      time: 0,
      coupons: [],
      navLabel: '<span class="icon-zuohua"></span>'
    };
  },
  methods: {
    getData() {
      getCoupons().then((res) => {
        this.coupons = res.data.data.list;
      });
    }
  },
  mounted() {
    this.getData()
    this.timer = setInterval(this.getData, 10000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
};
</script>
<style scoped>
  .couponCarousel {
    background-image: url("../../assets/coupon.jpg");
    background-repeat: no-repeat;
    height: 160px;
    margin-top: 50px;
  }
  .VueCarousel-slide{text-align: center;}
  .responsive-img{height:240px;border-radius: 8px;}
  ::v-deep .icon-zuohua{color:#a9a9a9;font-size: 32px;}
  ::v-deep .VueCarousel-navigation-prev{transform: translateY(-50%) translateX(0%)}

  ::v-deep .VueCarousel-navigation-next{transform: translateY(-50%) translateX(0%) rotate(180deg)}
  ::v-deep .VueCarousel-navigation--disabled{opacity: .2;}
  ::v-deep .VueCarousel-navigation-button:focus{outline: none}

  .content{
    margin-top: 50px;
  }

  .name {
    font-size: 30px;
    margin-left: 50px;
    color: black;
    font-weight: bold;
    padding: 20px;
  }

  .amount {
    font-size: 50px;
    float: left;
    margin-left: 50px;
    color: red;
    font-weight: bold;
  }
</style>